<template>
    <!--  1. 判断是否为会员：is_vip 0 不是会员 1是会员，如果是会员有头像框、会员订阅套餐类型subscribe_name、订阅有效期j结束时间subscribe_ended
          2. 判断是否为代理商：is_agency 0 不是代理商 1是代理商
            1 代理商已绑定：显示权益码和过期时间
            0 ：判断是否有权益码 equity_code， if equity_code > 0 显示权益码和'已绑定'状态，否则显示 '去绑定' 按钮
          3. 角色显示条件: 先判断是否为代理，是==不显示 否==判断是否为企业
                        是企业显示角色，不是企业不显示
          4. 去绑定代理按钮显示样式：审核状态0==未申请（去绑定）；审核状态1==申请中（不可点击）；审核状态2==已通过（显示权益码和过期时间）；审核状态3==已拒绝（点击查看原因）（已拒绝文字带上去绑定链接，“点击查看原因”带拒绝原因的弹框）
          5. 代理申请按钮显示样式：同上
     -->
    <div class="con_top  p20">
        <div class="item i_c_1 p20">
            <div class="secTitle">
                <div class="bar"></div>
                基本信息
            </div>
            <div class="info_b">
                <div class="info_l2" v-if="userInfo.is_vip">
                    <img class="avatar" :src="userInfo.head_pic || circleUrl" alt="">
                    <img class="vip" src="@/assets/images/person/accountManage/vip.png" alt="">
                </div>
                <div class="info info_l1" v-if="!userInfo.is_vip">
                    <el-avatar :size="100" :src="userInfo.head_pic || circleUrl"></el-avatar>
                </div>
                <div class="info info_m">
                    <div class="info-item">
                        <div class="label">名称：</div>
                        <div class="value">
                            <div class="txt" v-if="!nameFlag">{{ userInfo.nickname }}</div>
                            <i class="el-icon-edit edit_icon" v-if="!nameFlag" @click="nameFlagChange"></i>
                            <el-input class="inp" ref="nameInp" size="small" maxlength="16" v-if="nameFlag"
                                v-model="userInfo.nickname" @blur="editName()"></el-input>
                            <div class="apply_btn" @click="applyBtn()" v-if="userInfo.kol_status == 0">申请入驻
                                <img src="@/assets/images/person/accountManage/heart.png" alt="">
                            </div>
                            <div class="apply_btn apply_btn_err" v-if="userInfo.kol_status == 1">审核中...
                                <img src="@/assets/images/person/accountManage/heart_err.png" alt="">
                            </div>
                            <div class="s_c ml5" v-if="userInfo.kol_status == 2">入驻成功</div>
                            <div class="apply_btn" @click="applyBtn()" v-if="userInfo.kol_status == 3">已拒绝
                                <img src="@/assets/images/person/accountManage/heart.png" alt="">
                            </div>
                            <div class="p_warn ml5" v-if="userInfo.kol_status == 3" @click="kolLook">(查看拒绝原因)</div>

                        </div>
                    </div>
                    <div class="info-item">
                        <div class="label">手机号：</div>
                        <div class="value">
                            {{ userInfo.mobile }}
                        </div>
                    </div>
                    <div class="info-item">
                        <div class="label">企业：</div>
                        <div class="value">{{ userInfo.corp_name }}</div>
                    </div>
                    <div class="info-item" v-if="userInfo.is_agency == 0 && userInfo.corp_id > 0">
                        <div class="label">角色：</div>
                        <div class="value">
                            <div class="role_btn">{{ userInfo.member_role_name }}</div>
                        </div>
                    </div>
                </div>
                <div class="info info_r">
                    <!-- <div class="info-item">
                        <div class="label">手机号：</div>
                        <div class="value">
                            {{ userInfo.mobile }}
                        </div>
                    </div> -->
                    <div class="info-item">
                        <div class="label">组织：</div>
                        <div class="value">{{ userInfo.member_org_name }}</div>
                    </div>
                    <div class="info-item" v-if="userInfo.is_agency && userInfo.equity_code">
                        <div class="label">代理资质：</div>
                        <div class="value">
                            <div class="p_c" @click="endowmentsBtn">查看详情</div>
                        </div>
                    </div>
                    <div class="info-item">
                        <div class="label">代理商权益码：</div>
                        <div class="value" v-if="userInfo.is_agency && userInfo.equity_code">
                            <div class="copy_txt">
                                <div class="span1">{{ userInfo.equity_code }}</div>
                                <img class="span2" src="@/assets/images/person/accountManage/copy.png" alt=""
                                    @click="copyBtn(userInfo.equity_code)">
                                <img class="span3" src="@/assets/images/person/accountManage/reset.png" alt=""
                                    @click="refreshBtn()">
                                <div class="span4">({{ userInfo.agancy_expire }}后到期)</div>
                            </div>
                        </div>
                        <div class="value" v-if="!userInfo.is_agency && userInfo.equity_code">
                            <div class="s_c">已绑定</div>
                            <div class="copy_txt">
                                <div class="span1">{{ userInfo.equity_code }}</div>
                                <img class="span2" src="@/assets/images/person/accountManage/copy.png" alt=""
                                    @click="copyBtn(userInfo.equity_code)">
                            </div>
                        </div>
                        <div class="value" v-if="!userInfo.is_agency && !userInfo.equity_code && $has('538')">
                            <div class="p_c" v-if="userInfo.agency_status == 0" @click="bindBtn()">去绑定</div>
                            <div class="p_err" v-if="userInfo.agency_status == 1">审核中</div>
                            <div class="p_c" v-if="userInfo.agency_status == 3" @click="bindBtn()">已拒绝</div>
                            <div class="p_warn" v-if="userInfo.agency_status == 3" @click="agencyLook">(查看拒绝原因)</div>
                        </div>
                        <!-- <div class="value">
                            <div class="p_c" @click="bindBtn()">去绑定</div>
                            <div class="p_err">申请中</div>
                            <div class="p_c" @click="bindBtn()">已拒绝</div>
                            <div class="p_warn ml5" @click="agencyLook">(查看拒绝原因)</div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="r_t" v-if="userInfo.is_vip">
                <div class="my_member">我的会员：{{ userInfo.subscribe_name }}</div>
                <div class="times">({{ userInfo.subscribe_ended }}到期)</div>
                <div class="btn" @click="interestsBtn()">更多权益 ></div>
            </div>
        </div>
        <div class="item i_c_2 p20">
            <div class="secTitle">我的权益</div>
            <div class="data-list">
                <div class="data-item" v-for="(item, index) in dataList" :key="index">
                    <div class="data-label">
                        <img :src="item.img1" alt="">
                        {{ item.label }}
                    </div>
                    <div class="data-value" v-if="index == 3 || index == 4">
                        <template v-if="item.h > 0">
                            <div class="num">{{ item.h }}</div>
                            <span class="unit">时</span>
                        </template>
                        <template v-if="item.m > 0">
                            <div class="num">{{ item.m }}</div>
                            <span class="unit">分钟</span>
                        </template>
                        <div class="num">{{ item.s }}</div>
                        <span class="unit">秒</span>
                    </div>
                    <div class="data-value" v-else>
                        <div class="num">{{ item.value }}</div>
                        <img v-if="index == 0" :src="item.img2" alt="">
                        <span class="unit">{{ item.unit }}</span>
                    </div>
                </div>
            </div>
        </div>
        <open-member ref='dialogMember'></open-member>
        <endowments ref="endowments" :agency_qual="userInfo.agency_qual"></endowments>
        <bind-recomend ref="BindRecomend" @refreshInfo="getInfo"></bind-recomend>
        <!-- 拒绝原因提示弹框 -->
        <point-out ref='refuseReason' type='account' dialogTitle="拒绝原因" dialogWidth='380px'
            :iconShow='false' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">{{ refuseReason }}</div>
            </div>
        </point-out>
    </div>
</template>
<script>
import OpenMember from '@/components/invest_money/open_member.vue' // 弹框==开通会员
import Endowments from './endowments.vue' // 资质详情
import BindRecomend from '../bind_recomend/bind_recomend.vue' // 绑定推荐人
import PointOut from '@/components/point_out/point_out.vue'//提示弹框

import { mapState } from "vuex"

export default {
    components: {
        OpenMember,
        Endowments,
        BindRecomend,
        PointOut,
    },
    data() {
        return {
            nameFlag: false,
            circleUrl: require('@/assets/images/img/header.png'),
            storeName: '',
            refuseReason: '',
        }
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.userInfo.user_info,
            // storeName: (state) => state.userInfo.user_info.nickname,
        }),
        // 我的权益
        dataList() {
            let arr = [
                {
                    label: '钻石余额',
                    value: this.userInfo.diamond || 0,
                    img1: require('@/assets/images/person/accountManage/interests_1.png'),
                    img2: require('@/assets/images/person/accountManage/zuan.png'),
                    unit: '',
                },
                {
                    label: '绘画次数',
                    value: this.userInfo.drawing || 0,
                    img1: require('@/assets/images/person/accountManage/interests_2.png'),
                    img2: '',
                    unit: '次',

                },
                {
                    label: '高级形象定制',
                    value: this.userInfo.advanced_cust || 0,
                    img1: require('@/assets/images/person/accountManage/interests_3.png'),
                    img2: '',
                    unit: '次',
                },
                {
                    label: '视频时长余额',
                    value: 0,
                    img1: require('@/assets/images/person/accountManage/interests_4.png'),
                    img2: '',
                    unit: '',
                    h: this.$utils.formatSeconds(this.userInfo.video_duration, 3).h || 0,
                    m: this.$utils.formatSeconds(this.userInfo.video_duration, 3).m || 0,
                    s: this.$utils.formatSeconds(this.userInfo.video_duration, 3).s || 0,
                },
                {
                    label: '音频时长余额',
                    value: 0,
                    img1: require('@/assets/images/person/accountManage/interests_5.png'),
                    img2: '',
                    unit: '',
                    h: this.$utils.formatSeconds(this.userInfo.audio_duration, 3).h || 0,
                    m: this.$utils.formatSeconds(this.userInfo.audio_duration, 3).m || 0,
                    s: this.$utils.formatSeconds(this.userInfo.audio_duration, 3).s || 0,
                },
                {
                    label: '专业形象定制',
                    value: this.userInfo.major_cust || 0,
                    img1: require('@/assets/images/person/accountManage/interests_6.png'),
                    img2: '',
                    unit: '次',
                },
            ]
            return arr
        }
    },
    created() {
        this.storeName = this.userInfo.nickname
        this.getInfo()
    },
    methods: {
        // 查看账户信息
        getInfo() {
            this.$store.dispatch('userInfo/getUserInfo')
        },
        nameFlagChange() {
            this.nameFlag = true
            this.$nextTick(() => {
                this.$refs.nameInp.focus()
            })
        },
        // 编辑名称
        editName() {
            this.nameFlag = false

            if (!this.userInfo.nickname.match(/^[\u4E00-\u9FA5a-zA-Z0-9]{3,16}$/)) {
                this.$message.error('输入长度3-16位，支持中文、数字、英文')
                this.userInfo.nickname = this.storeName
                return
            }
            this.$personApi.accountEditName({ nickname: this.userInfo.nickname }).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.$store.dispatch('userInfo/getUserInfo')
                    this.storeName = this.userInfo.nickname


                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 资质详情
        endowmentsBtn() {
            this.$refs.endowments.openDialogBtn()
        },
        // 更多权益
        interestsBtn() {
            this.$refs.dialogMember.openDialogBtn()
        },
        // 去绑定
        bindBtn() {
            this.$refs.BindRecomend.openDialogBtn1()
        },
        // 申请入驻
        applyBtn() {
            // if (!this.$has('539')) {
            //     this.$errMsg('没有审核权限')
            //     return
            // }
            const { href } = this.$router.resolve({
                path: "/callUp",
            });
            window.open(href, "_blank")
        },
        // 复制
        copyBtn(val) {
            this.$copyText(val).then(
                (e) => {
                    this.$message.success("复制成功");
                },
                function (e) { }
            );
        },
        // 刷新
        refreshBtn() {
            this.getInfo()
        },
        // 代理拒绝原因
        agencyLook() {
            this.refuseReason = this.userInfo.agency_remarks
            this.$refs.refuseReason.openDialogBtn()
        },
        // KOL拒绝原因
        kolLook() {
            this.refuseReason = this.userInfo.kol_remarks
            this.$refs.refuseReason.openDialogBtn()
        },
    }
}
</script>
<style lang='scss' scoped>
.con_top {
    background: rgba(255, 255, 255, 0.47);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;

    .item {
        height: 226px;
        border-radius: 20px;
    }

    .i_c_1 {
        padding: 30px 0 30px 30px;
        width: 1320px;
        min-width: 600px;
        background: linear-gradient(135deg, #FFFFFF 0%, #EBF3FF 100%);
        position: relative;

        .secTitle {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-family: PingFangSC-Medium,
                PingFang SC;
            font-weight: 500;
            color: #333333;

            .bar {
                width: 5px;
                height: 18px;
                background: $blueColor1;
                border-radius: 3px;
                margin-right: 10px;
            }

        }

        .info_b {
            width: 100%;
            padding: 0 0 0 20px;
            display: flex;
            align-items: flex-start;

            .info {
                .info-item {
                    display: flex;
                    align-items: center;
                    margin-top: 16px;
                    flex-wrap: nowrap;

                    .label {
                        font-size: 14px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                    }

                    .value {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        display: flex;
                        align-items: center;
                        flex-wrap: nowrap;

                        .apply_btn {
                            padding: 0 10px;
                            line-height: 26px;
                            background: rgba(255, 116, 50, 0.12);
                            border-radius: 13px;
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #FF7432;
                            text-align: center;
                            margin-left: 5px;
                            display: flex;
                            align-items: center;
                            cursor: pointer;

                            img {
                                width: 14px;
                                height: 14px;
                                margin-left: 5px;
                            }
                        }

                        .apply_btn.apply_btn_err {
                            color: #999999;
                            background: rgba(176, 176, 176, 0.12);
                        }

                        .role_btn {
                            width: 84px;
                            line-height: 26px;
                            background: #F0E7FF;
                            border-radius: 13px;
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #782EF2;
                            text-align: center;

                        }

                        .copy_txt {

                            border-radius: 2px;
                            display: flex;
                            margin-left: 5px;

                            .span1 {
                                background: #fff;
                                font-size: 12px;
                                font-weight: 400;
                                color: #666666;
                                padding: 2px 4px;
                            }

                            .span2 {
                                width: 22px;
                                height: 22px;
                                cursor: pointer;
                            }

                            .span3 {
                                width: 22px;
                                height: 22px;
                                margin-left: 10px;
                                cursor: pointer;
                            }

                            .span4 {
                                color: #FF2828;
                                font-size: 14px;
                                margin-left: 10px;
                            }
                        }

                        .edit_icon {
                            cursor: pointer;
                            margin-left: 5px;
                        }

                        .inp {
                            width: 150px;
                        }
                    }

                }
            }

            .info_l1 {
                margin-top: 20px;
            }

            .info_l2 {
                width: 100px;
                height: 100px;
                background: url('~@/assets/images/person/accountManage/avatar_bg.png') no-repeat;
                background-size: cover;
                margin-top: 20px;
                position: relative;

                .avatar {
                    position: absolute;
                    top: 15px;
                    left: 10px;
                    width: 80px;
                    height: 80px;
                    border-radius: 100%;
                }

                .vip {
                    position: absolute;
                    top: 80px;
                    left: 20px;
                    width: 55px;
                    height: 25px;
                }
            }

            .info_m {
                margin-left: 6%;
            }

            .info_r {
                margin-left: 10%;
            }
        }

        .type0 {
            align-items: center !important;

        }

        .r_t {
            position: absolute;
            right: 0;
            top: 0;
            // width: 334px;
            // height: 33px;
            background: #FFFFFF;
            border-radius: 0px 10px 0px 10px;

            font-size: 12px;
            display: flex;
            line-height: 33px;
            text-align: center;
            padding: 0 10px;

            .my_member {
                color: #333;
                margin-left: 5px;

            }

            .times {
                color: #FF2828;
                margin-left: 5px;
            }

            .btn {
                color: $blueColor1;
                margin-left: 5px;
                cursor: pointer;
            }
        }
    }

    .i_c_2 {
        padding: 30px 0 30px 30px;
        /* min-width: calc(100% - 1000px); */
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // background: linear-gradient(222deg, #2571FF 0%, #705FFF 100%);
        background: url('~@/assets/images/person/accountManage/interests_bg.png') no-repeat;
        background-size: cover;

        .secTitle {
            font-size: 16px;
            font-weight: 500;
            color: $blueColor1;

            width: 36px;
            background: linear-gradient(151deg, #F0EEFF 0%, #E4DFFF 100%);
            border-radius: 18px;
            padding: 10px;
        }

        .data-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: center;

            .data-item {
                width: 31%;
                padding: 15px 10px 15px 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .data-label {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    font-family: PingFangSC-Regular,
                        PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;

                    img {
                        margin-right: 5px;
                        width: 16px;
                        height: 16px;
                    }
                }

                .data-value {
                    display: flex;
                    align-items: baseline;
                    margin-top: 10px;

                    .num {
                        font-size: 24px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;

                    }

                    img {
                        margin-left: 5px;
                        width: 24px;
                        height: 24px;
                    }

                    .unit {
                        font-size: 14px;
                        font-weight: 400;
                        color: #FFFFFF;

                    }
                }

            }
        }
    }

}

.s_c {
    color: #419645;
}

.p_c {
    color: $blueColor1;
    cursor: pointer;
}

.p_err {
    color: #999;
}

.p_warn {
    color: red;
    cursor: pointer;
}

.ml5 {
    margin-left: 5px;
}
</style>
