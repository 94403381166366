<!-- @Author: Yu_Bo -->
<template>
  <div class='invitation_code zc_dialog_box'>
    <el-dialog :visible.sync="dialogVisible" width="576px" :close-on-click-modal='false'>
      <div slot="title">
        <div class="name">新增邀请码</div>
      </div>
      <div class="inv_main">
        <div class="main_text">
          <div class="left"><span>*</span>邀请码数量</div>
          <div class="right">
            <el-input type="number" v-model.number="num" placeholder="请输入邀请码数量"></el-input>
          </div>
        </div>
        <div class="main_text">
          <div class="left"><span>*</span>邀请码有效期</div>
          <div class="right">
            <el-date-picker v-model="time_validity" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期" value-format="yyyy-MM-dd" :disabled="is_expire" clearable>
            </el-date-picker>
          </div>
        </div>
        <div class="main_text">
          <div class="left"></div>
          <div class="right">
            <el-checkbox :disabled="time_validity && time_validity.length > 0" v-model="is_expire">永久有效</el-checkbox>
          </div>
        </div>
        <div class="main_text_txt">
          您一共可以生产{{ code_num }}个邀请码，如需更多邀请码，
          <el-popover popper-class="popover_corner" :offset='-50' placement="left-end" width="170" trigger="hover">
            <div class="corner_box">
              <div class="img">
                <img src="@/assets/images/nav/person_icon_c.png" alt="">
              </div>
              <div class="title">添加客服微信</div>
              <div class="kf_img">
                <div class="top"></div>
                <div class="bottom"></div>
                <div class="left"><span></span></div>
                <div class="right"><span></span></div>
                <img class="img_ewm" src="@/assets/images/img/service_img.png" alt="">
              </div>
            </div>
            <span slot="reference">联系客服</span>
          </el-popover>
        </div>
      </div>
      <div slot="footer">
        <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
        <el-button class="btnBgColor_blue" size="small" type="primary" @click="affirmBtn" :loading="subLoading">确
          定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      time_validity: [],
      code_num: 0,
      num: '',
      is_expire: false,
      start_date: '',
      end_date: '',
      subLoading: false,
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() { },
  methods: {
    // 打开弹框
    openDialogBtn() {
      this.dialogVisible = true
      this.getCodeNum()
    },
    // 取消
    cancelBtn() {
      this.dialogVisible = false
    },
    // 邀请码可添加数量
    getCodeNum() {
      this.$personApi.inviteCodeNum().then(res => {
        if (res.code == 1000) {
          this.code_num = res.result.code_num
        } else {
          this.$errMsg(res.message)
        }
      })
    },
    // 确定
    affirmBtn() {
      if (!this.num) {
        this.$errMsg('请输入邀请码数量')
        return
      }
      if (!this.time_validity && !this.is_expire) {
        this.$errMsg('请选择邀请码有效期')
        return
      }
      const data = {
        num: this.num,
        is_expire: this.is_expire ? 1 : 2,
        start_date: this.time_validity && this.time_validity.length ? this.time_validity[0] : '',
        end_date: this.time_validity && this.time_validity.length ? this.time_validity[1] : '',
      }
      this.subLoading = true
      this.$personApi.inviteApplyCodeAdd(data).then(res => {
        this.subLoading = false
        if (res.code == 1000) {
          this.$succMsg(res.message)
          this.cancelBtn()
          this.$emit('refreshList')
        } else {
          this.$errMsg(res.message)
        }
      })
    },
  },
}
</script>

<style lang='scss' scoped>
.invitation_code {
  .inv_main {
    width: 100%;
    padding: 10px 30px 0;

    .main_text {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        width: 100px;
        text-align: right;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;

        span {
          padding-right: 5px;
          color: #E00C25;
        }
      }

      .right {
        width: 360px;

        .el-input {
          width: 100%;
        }

        .el-date-editor {
          width: 100%;
        }
      }
    }

    .main_text_txt {
      margin-bottom: 0;
      padding-top: 10px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9097A5;
      display: flex;

      span {
        cursor: pointer;
        color: #2E4BF2;
      }
    }
  }
}
</style>